import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import Popup from "@/components/common/Popup";
import useSocket from "@/common/hook/useSocket";

const SocketErrorPopup = () => {
  const socket = useSocket();
  const { t } = useTranslation("common");
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    socket.on("error", ({ code }) => {
      setPopup(code || true);
    });

    return () => socket.off("error");
  }, [socket]);

  if (popup === false) {
    return null;
  }

  return (
    <Popup size="sm" onClose={() => setPopup(false)}>
      <div className="text-xl font-semibold lg:text-3xl">{t`error.general`}</div>
      <div className="text-sm text-grey-800 lg:text-xl">
        {t("error.try-again-later", { code: typeof popup === "boolean" ? -1 : popup })}
      </div>
    </Popup>
  );
};

export default SocketErrorPopup;
