import { useEffect, useState } from "react";
import useIsMobile from "@/common/hook/useIsMobile";

export default function useHeaderScroll(showSearch) {
  const [isScrolled, setScrolled] = useState(false);
  const [search, setSearch] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleIsScrolled = () => setScrolled(window.scrollY > 20);
    handleIsScrolled();

    if (isMobile) {
      if (showSearch) setSearch(false);
      document.addEventListener("scroll", handleIsScrolled);
      return () => document.removeEventListener("scroll", handleIsScrolled);
    }

    const handleScroll = () => {
      if (showSearch) setSearch(window.scrollY >= 915);
      handleIsScrolled();
    };
    handleScroll();

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [isMobile, showSearch]);

  return [search, isScrolled];
}
