import { useEffect, useRef, useState } from "react";

export default function useOnceInView() {
  const elementRef = useRef(null);
  const observerRef = useRef(null);
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      const { isIntersecting } = entry;

      if (isIntersecting) {
        setIsViewed(true);
        if (observerRef.current) observerRef.current = observerRef.current.disconnect();
      }
    });

    observerRef.current.observe(elementRef.current);

    return () => observerRef.current?.disconnect();
  }, []);

  return [isViewed, elementRef];
}
