import getScrollbarWidth from "@/utils/getScrollbarWidth";

/**
 * Remove scrollbar from viewport for modals
 * @param open
 * @returns {boolean}
 */
export default function addNoScroll(open = true) {
  if (open) {
    document.body.style.paddingRight = `${getScrollbarWidth()}px`;
  } else {
    document.body.style.removeProperty("padding-right");
  }

  return document.body.classList.toggle("no-scroll", open);
}
