import { useCallback, useRef, useState } from "react";
import useIsomorphicLayoutEffect from "@/utils/useIsomorphicLayoutEffect";
import { createPopper } from "@popperjs/core";

const OPTIONS = {
  placement: "top",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 8],
      },
    },
    { name: "eventListeners", enabled: false },
  ],
};

export default function usePopper(popperRef, options = OPTIONS, mergeOptions = false) {
  const referenceRef = useRef(null);
  const popperInstanceRef = useRef(null);
  const [isEnable, setEnable] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (referenceRef.current === null || popperRef.current === null) {
      throw new Error("usePopper refs is null");
    }

    popperInstanceRef.current = createPopper(
      referenceRef.current,
      popperRef.current,
      mergeOptions ? { ...OPTIONS, ...options } : options
    );

    return () => {
      popperInstanceRef.current.destroy();
      popperInstanceRef.current = null;
    };
  }, []);

  const disable = useCallback(() => {
    popperInstanceRef.current.setOptions((o) => ({
      ...o,
      modifiers: [...o.modifiers, { name: "eventListeners", enabled: false }],
    }));

    setEnable(false);
  }, []);

  const enable = useCallback(() => {
    popperInstanceRef.current.setOptions((o) => ({
      ...o,
      modifiers: [...o.modifiers, { name: "eventListeners", enabled: true }],
    }));

    // Update its position
    popperInstanceRef.current.update();

    setEnable(true);
  }, []);

  return {
    popperInstanceRef,
    referenceRef,
    enable,
    disable,
    isEnable,
  };
}
