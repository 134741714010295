import { useEffect } from "react";
import addNoScroll from "@/utils/addNoScroll";

const useNoScroll = () => {
  useEffect(() => {
    addNoScroll(true);
    return () => addNoScroll(false);
  }, []);
};

export default useNoScroll;
