import clsx from "clsx";
import usePopper from "@/common/hook/usePopper";
import useOutsideClick from "@/common/hook/useOutsideClick";

const Dropdown = ({
  buttonClass,
  buttonContent,
  wrapperClass,
  className = "w-52",
  placement = "bottom-start",
  children,
}) => {
  // eslint-disable-next-line no-use-before-define
  const popperRef = useOutsideClick(() => disable());
  const { referenceRef, enable, disable, isEnable } = usePopper(popperRef, { placement }, true);

  return (
    <div className={clsx("relative inline-flex", wrapperClass)}>
      <button ref={referenceRef} type="button" className={buttonClass} onClick={enable}>
        {buttonContent}
      </button>
      <div
        ref={popperRef}
        className={clsx("animate-fade-in rounded-lg bg-white shadow-lg", !isEnable && "hidden", className)}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
