import React from "react";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import setLanguage from "next-translate/setLanguage";
import Image from "next/image";
import Dropdown from "@/components/common/Dropdown";
import IconChevronDown from "@/common/icons/chevron-down.svg";

const OPTIONS = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "de",
    label: "Deutsch",
  },
];

const ChangeLanguage = () => {
  const { locale } = useRouter();
  const currentOption = OPTIONS.find((option) => option.value === locale);

  return (
    <Dropdown
      wrapperClass="mt-2 lg:mt-0 lg:ml-auto"
      buttonClass="h-10"
      buttonContent={
        <div className="flex items-center">
          <Image src={`/flags/${currentOption.value}.svg`} alt="Flag" width={24} height={24} />
          <div className="ml-2 mr-0.5 text-xs font-semibold leading-none">{currentOption.value.toUpperCase()}</div>
          <IconChevronDown className="h-4 w-4" />
        </div>
      }
      placement="top"
    >
      {OPTIONS.map((option) => (
        <button
          key={option.value}
          className="button-text flex h-10 w-full items-center gap-2"
          type="button"
          onClick={async () => {
            setCookie(null, "NEXT_LOCALE", option.value, {
              maxAge: 100 * 24 * 60 * 60,
              path: "/",
              sameSite: true,
            });
            await setLanguage(option.value);
          }}
        >
          <Image src={`/flags/${option.value}.svg`} alt="Flag" width={16} height={16} />
          <span>{option.label}</span>
        </button>
      ))}
    </Dropdown>
  );
};

export default ChangeLanguage;
