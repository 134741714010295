import { useEffect, useState } from "react";

export default function useIsMobile() {
  const [targetReached, setTargetReached] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: 640px)`);

    // Check on mount (callback is not called until a change occurs)
    setTargetReached(media.matches);

    const updateTarget = (e) => setTargetReached(e.target.matches);
    media.addEventListener("change", updateTarget);
    return () => media.removeEventListener("change", updateTarget);
  }, []);

  return targetReached;
}
