import Image from "next/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import useUser from "@/common/hook/user/useUser";
import ChangeLanguage from "@/components/core/ChangeLanguage";

const Footer = ({ hasBackground = true }) => {
  const { t } = useTranslation("common");
  const isAuthenticated = useUser();

  return (
    <footer className={clsx("w-full shrink-0 pt-16 lg:pt-24", hasBackground && "bg-purple-800")}>
      <div className="container">
        <div className="flex items-end">
          <div className="hidden overflow-hidden lg:flex">
            <Image src="/footer/hand.png" alt="Vialantis Logo in Hand" width={103} height={259} />
          </div>
          <div className="mb-9 flex w-full grow flex-col items-center justify-between lg:ml-20 lg:w-auto lg:items-stretch">
            <div className="mb-12 ml-3 flex w-full flex-col items-center gap-6 lg:flex-row">
              <div className="flex items-center gap-6">
                <a href="https://www.facebook.com/Vialantis" target="_blank" rel="noreferrer">
                  <Image src="/facebook.svg" width={48} height={48} alt="Facebook" />
                </a>
                <a href="https://twitter.com/Vialantis" target="_blank" rel="noreferrer">
                  <Image src="/twitter.svg" width={48} height={48} alt="Twitter" />
                </a>
                <a href="https://www.instagram.com/vialantis.official/" target="_blank" rel="noreferrer">
                  <Image src="/instagram.svg" width={48} height={48} alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/vialantis/" target="_blank" rel="noreferrer">
                  <Image src="/footer/linkedin.png" width={48} height={48} alt="Linkedin" />
                </a>
              </div>
              <div className="lg:ml-auto">
                <div className="mb-2 text-center text-xs text-grey-700 lg:text-left">{t`footer.sponsor`}</div>
                <a href="https://www.christa-appelt.de/" target="_blank" rel="noopener noreferrer">
                  <Image src="/logo-ca.png" alt="Christian Appelt" width={135} height={25} quality={100} />
                </a>
              </div>
            </div>
            <nav className="flex w-4/5 flex-col items-center gap-2 pb-12 lg:mt-0 lg:w-full lg:flex-row lg:gap-6 lg:pb-0">
              <Link href="/imprint">
                <a className="button-text">{t`footer.imprint`}</a>
              </Link>
              <Link href="/terms-and-conditions">
                <a className="button-text">{t`footer.conditions`}</a>
              </Link>
              <Link href="/privacy-policy">
                <a className="button-text">{t`footer.privacy`}</a>
              </Link>
              <Link href="/terms-of-use">
                <a className="button-text">{t`footer.terms-of-use`}</a>
              </Link>

              <div className="mt-6 flex w-full flex-col space-y-3 lg:mt-0 lg:w-auto lg:flex-row lg:space-y-0 lg:space-x-3">
                <Link href="/start">
                  <a className="button text-white">{t`become-consultant`}</a>
                </Link>
                {!isAuthenticated && (
                  <Link href="/login">
                    <a className="button-outline">{t`login`}</a>
                  </Link>
                )}
              </div>

              <ChangeLanguage />
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
