import clsx from "clsx";
import useNoScroll from "@/common/hook/useNoScroll";
import { XIcon } from "@heroicons/react/outline";

const SIZES = {
  xs: "lg:w-[544px]",
  sm: "min-h-[392px] lg:w-[670px]",
  md: "min-h-[440px] lg:w-[801px]",
  lg: "min-h-[496px] lg:w-[896px]",
  xl: "min-h-[496px] lg:w-[1000px]",
};

const Popup = ({ children, className, onClose, closeFromOutside = true, size = "md", alignStart = false }) => {
  useNoScroll();

  return (
    <div
      className="flex-center fixed inset-0 z-40 h-full w-full bg-black bg-opacity-60"
      onClick={closeFromOutside ? onClose : undefined}
      role="presentation"
    >
      <div
        className={clsx(
          "flex max-h-full w-[95vw] flex-col items-center overflow-y-auto rounded-lg bg-white p-5",
          SIZES[size]
        )}
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        {onClose && (
          <button
            type="button"
            className="button-icon mb-5 h-8 w-8 shrink-0 self-end rounded-full"
            onClick={onClose}
            aria-label="Close Popup"
          >
            <XIcon className="h-4 w-4" />
          </button>
        )}
        <div className={clsx("w-full grow flex-col", alignStart ? "flex" : "flex-center", className)}>{children}</div>
        {/* For close button height */}
        {onClose && <div className="mt-5 h-8" />}
      </div>
    </div>
  );
};

export default Popup;
