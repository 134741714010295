import { useEffect, useRef } from "react";

const useOutsideClick = (onClick) => {
  const ref = useRef(null);

  useEffect(() => {
    if (onClick && ref.current !== null) {
      const handleClick = ({ target }) => {
        if (ref.current && !ref.current.contains(target)) {
          onClick(target);
        }
      };

      document.addEventListener("mousedown", handleClick);

      return () => document.removeEventListener("mousedown", handleClick);
    }

    return null;
  }, [onClick, ref]);

  return ref;
};

export default useOutsideClick;
