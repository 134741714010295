import { memo, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";
import DropdownMenu from "@/components/common/DropdownMenu";
import IconNotification from "@/common/icons/notification.svg";
import useSocket from "@/common/hook/useSocket";
import NotificationItem from "@/components/common/NotificationItem";
import request from "@/utils/request";

const Notifications = () => {
  const { t } = useTranslation("common");
  const { data: notifications = [], mutate } = useSWR("/notify");
  const [isOpen, setOpen] = useState(false);

  const hasUnread = useMemo(
    () => notifications.some((notification) => notification.seen_date === null),
    [notifications]
  );

  const socket = useSocket();
  useEffect(() => {
    socket.on("notification-refresh", () => mutate());
    return () => socket.off("notification-refresh");
  }, [socket, mutate]);

  useEffect(() => {
    if (isOpen && hasUnread) {
      request.get("/notify/clean").then(
        () => {
          mutate(
            notifications.map((n) => {
              if (n.seen_date === null) {
                return { ...n, seen_date: Date.now() };
              }

              return n;
            }),
            false
          );
        },
        () => {}
      );
    }
  }, [hasUnread, isOpen, mutate, notifications]);

  return (
    <DropdownMenu
      title={
        <div className="relative">
          <IconNotification className="h-4 w-4 md:h-6 md:w-6" />
          {hasUnread && (
            <span className="absolute top-0 right-0 h-2.5 w-2.5 rounded-full border-2 border-purple-800 bg-blue" />
          )}
        </div>
      }
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div className="py-4 px-5 text-xs font-bold text-grey-900">{t`notifications.title`}</div>
      <div className="thin-scrollbar flex grow flex-col overflow-y-auto overflow-x-hidden px-2 sm:min-w-[16rem]">
        {(notifications?.length ?? 0) > 0 ? (
          notifications.map((notification) => (
            <div key={notification.id} className="rounded-lg p-2.5 hover:bg-purple-700 sm:w-[32rem]">
              <NotificationItem
                id={notification.id}
                notification={notification.message.message}
                type={notification.message.type}
                createdAt={notification.created_at}
                handleClick={() => setOpen(false)}
                chatAvatarClassName="w-12 h-12 text-2xl"
              />
            </div>
          ))
        ) : (
          <span className="px-3 text-xs">{t`notifications.not-found`}</span>
        )}
      </div>
      {/* HACK FOR OVERFLOW PADDING */}
      <div className="block pb-2.5" />
    </DropdownMenu>
  );
};

export default memo(Notifications);
