import { useEffect } from "react";
import useSWR from "swr";
import { useDispatch, useSelector } from "react-redux";
import { setPlayNotificationSound } from "@/store/commonSlice";
import { setChatCount } from "@/store/chatSlice";

export default function useChatCount() {
  const dispatch = useDispatch();
  const chatCount = useSelector((state) => state.chat.chatCount);
  const chatOpen = useSelector((state) => state.chat.open);
  const { data } = useSWR("/chat/count/unread", {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  useEffect(() => {
    if (data && data.count !== chatCount) {
      dispatch(setChatCount(data.count));

      if (data.count > 0 && chatOpen === false) {
        dispatch(setPlayNotificationSound(true));
      }
    }
  }, [chatCount, chatOpen, data, dispatch]);

  return { data };
}
