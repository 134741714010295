import { memo, useRef, useEffect } from "react";
import clsx from "clsx";
import addNoScroll from "@/utils/addNoScroll";
import useOutsideClick from "@/common/hook/useOutsideClick";
import { XIcon } from "@heroicons/react/outline";

const Sidebar = ({ icon: Icon, children, action, isOpen, setOpen, rightAlign = true }) => {
  const buttonRef = useRef();
  // h-24 top-24 for header height

  const containerRef = useOutsideClick(
    isOpen &&
      ((target) => {
        if (buttonRef.current && !buttonRef.current.contains(target)) setOpen(false);
      })
  );

  useEffect(() => {
    addNoScroll(isOpen);

    return () => {
      addNoScroll(false);
    };
  }, [isOpen]);

  return (
    <div>
      <button
        className={clsx("button-icon", isOpen && "border-blue text-blue")}
        onClick={() => setOpen(!isOpen)}
        ref={buttonRef}
        type="button"
        aria-label="Toggle Menu"
      >
        {isOpen ? (
          // eslint-disable-next-line tailwindcss/no-custom-classname
          <XIcon className="text-blue-500 h-4 w-4 text-blue-900 md:h-8 md:w-8" />
        ) : (
          <Icon className="h-4 w-4 md:h-6 md:w-6" />
        )}
      </button>
      <div
        className={clsx(
          "fixed inset-x-0 top-16 bottom-0 flex cursor-auto justify-end bg-black/60 transition-all duration-300 ease-linear sm:top-24",
          isOpen ? "visible opacity-100" : "invisible opacity-0",
          !rightAlign && "lg:justify-start"
        )}
      >
        <div
          ref={containerRef}
          className={clsx(
            "relative h-full min-w-[18rem] bg-white transition-all duration-300 ease-linear lg:min-w-[24rem]",
            // eslint-disable-next-line no-nested-ternary
            isOpen ? (rightAlign ? "lg:right-0" : "lg:left-0") : rightAlign ? "lg:-right-1/4" : "lg:-left-1/4"
          )}
        >
          <aside className="flex h-full flex-col p-5">
            <div className={clsx("flex", action ? "justify-between" : "justify-end", !rightAlign && "lg:hidden")}>
              {action}
            </div>
            {children}
          </aside>
        </div>
      </div>
    </div>
  );
};

export default memo(Sidebar);
