import React from "react";
import { useDispatch } from "react-redux";
import { toggleIsOpenCategoriesSidebar } from "@/store/commonSlice";
import useTranslation from "next-translate/useTranslation";

const SidebarOpenButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  return (
    <button
      className="button-text hidden lg:block"
      type="button"
      onMouseDown={(e) => e.stopPropagation()}
      onClick={() => dispatch(toggleIsOpenCategoriesSidebar(true))}
    >
      {t`all-categories`}
    </button>
  );
};

export default SidebarOpenButton;
