import { memo } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { DefaultSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import LiveCallPopup from "@/components/core/LiveCallPopup";
import SocketErrorPopup from "@/components/core/SocketErrorPopup";
import ChatCallHandler from "@/components/core/ChatCallHandler";
import Footer from "./Footer";
import Header from "./Header";

const CookieConsentPopup = dynamic(() => import("./CookieConsentPopup"), { ssr: false });
const Meta = dynamic(() => import("./Meta"), { ssr: false });

const LayoutComponent = ({
  children,
  className,
  hasFooter = true,
  hasFooterBackground = true,
  destroyContainer = false,
  showSearch = false,
  forceSearchBar = false,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className="flex min-h-screen flex-col">
      <DefaultSeo
        defaultTitle={t`meta.title`}
        description={t`meta.description`}
        titleTemplate="%s | Vialantis"
        openGraph={{
          type: "website",
          images: [{ url: "https://vialantis.com/branding-icon.png", width: 224, height: 248 }],
        }}
        twitter={{ cardType: "summary_large_image", site: "@Vialantis" }}
        additionalMetaTags={[{ name: "keywords", content: t`meta.keywords` }]}
      />
      <Meta />
      <Header forceSearchBar={forceSearchBar} showSearch={showSearch} destroyContainer={destroyContainer} />
      {/* mt-16 and mt-24 is header height */}
      <main className={clsx("mt-16 grow sm:mt-24", className)}>{children}</main>
      {hasFooter && <Footer hasBackground={hasFooterBackground} />}
      <CookieConsentPopup />
      <ChatCallHandler />
      <LiveCallPopup callType="video" />
      <LiveCallPopup callType="audio" />
      <SocketErrorPopup />
    </div>
  );
};

const Layout = memo(LayoutComponent);
export default Layout;
