import Link from "next/link";
import Image from "next/image";
import clsx from "clsx";
import dynamic from "next/dynamic";
import Notifications from "@/components/core/Header/Notifications";
import useHeaderScroll from "@/common/hook/header/useHeaderScroll";
import useUser from "@/common/hook/user/useUser";
import SidebarOpenButton from "@/components/core/Header/SidebarOpenButton";
import useChatCount from "@/common/hook/useChatCount";
import MainMenu from "./MainMenu";
import UploadButton from "./UploadButton";

const Chat = dynamic(() => import("./Chat"));
const UserMenu = dynamic(() => import("./UserMenu"));
const AuthenticationButtons = dynamic(() => import("./AuthenticationButtons"));
const SearchBar = dynamic(() => import("@/components/common/SearchBar"));

const Header = ({ forceSearchBar, showSearch, destroyContainer }) => {
  const { isAuthenticated, user } = useUser();
  const [canShowSearch, isScrolled] = useHeaderScroll(showSearch);
  useChatCount();

  return (
    <header
      className={clsx(
        "fixed top-0 z-40 flex h-16 w-full items-center justify-center bg-purple-800 transition duration-300 ease-linear sm:h-24",
        isScrolled && "shadow"
      )}
    >
      <section
        className={clsx(
          "flex w-full items-center justify-between gap-4",
          destroyContainer ? "mx-4 lg:mx-6" : "container"
        )}
      >
        <div className="flex shrink-0 items-center gap-1 lg:gap-2">
          <Link href="/">
            <a className="relative block h-[30px] w-[117.85px] cursor-pointer lg:h-[50px] lg:w-[176.77px]">
              <Image
                src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/img/vialantis-logo.svg`}
                alt="Vialantis Logo"
                layout="fill"
                quality={100}
              />
            </a>
          </Link>
          <div className="rounded-lg bg-grey-500 p-1 text-xs font-semibold text-white lg:px-2">BETA</div>
          <SidebarOpenButton />
        </div>
        {(forceSearchBar || canShowSearch) && <SearchBar isInHeader isDetailPage={forceSearchBar} />}
        <nav className="flex items-center gap-2">
          {user?.isConsultant === true && <UploadButton slug={user.slug} />}

          {isAuthenticated ? <Chat /> : <AuthenticationButtons />}
          <Notifications />
          {isAuthenticated && <UserMenu />}
          <MainMenu />
        </nav>
      </section>
    </header>
  );
};
export default Header;
