import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import IconMenu from "@/common/icons/menu.svg";
import Sidebar from "@/components/core/Header/Sidebar";
import IconChevronLeft from "@/common/icons/chevron-left.svg";
import useOnceInView from "@/common/hook/useOnceInView";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenCategoriesSidebar } from "@/store/commonSlice";

const MainMenuContainer = dynamic(() => import("./MainMenuContainer"));

const MainMenu = () => {
  const isOpen = useSelector((state) => state.common.isOpenCategoriesSidebar);
  const dispatch = useDispatch();
  const [isViewed, ref] = useOnceInView();
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => setSelectedCategory(null), [isOpen]);

  return (
    <Sidebar
      isOpen={isOpen}
      setOpen={(val) => dispatch(setIsOpenCategoriesSidebar(val))}
      icon={IconMenu}
      action={
        selectedCategory !== null && (
          <button
            type="button"
            className="button-icon h-8 w-8 self-end rounded-full lg:invisible"
            onClick={() => setSelectedCategory(null)}
            aria-label="Back"
          >
            <IconChevronLeft className="h-4 w-4" />
          </button>
        )
      }
      rightAlign={false}
    >
      <div ref={ref} className="max-h-full">
        {isViewed && (
          <MainMenuContainer selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
        )}
      </div>
    </Sidebar>
  );
};

export default MainMenu;
