import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import useSocket from "@/common/hook/useSocket";

const ChatCallHandlerPopup = dynamic(() => import("./ChatCallHandlerPopup"));

const ChatCallHandler = () => {
  const socket = useSocket();
  // {username, id}
  const [caller, setCaller] = useState(null);

  useEffect(() => {
    socket.on("chat.call", ({ username, id }) => setCaller({ username, id }));
    socket.on("chat.call.close", () => setCaller(null));

    return () => {
      socket.off("chat.call");
      socket.off("chat.call.close");
    };
  }, [socket]);

  if (caller === null) {
    return null;
  }

  return <ChatCallHandlerPopup onClose={() => setCaller(null)} id={caller.id} username={caller.username} />;
};

export default ChatCallHandler;
