import { useState } from "react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import DropdownMenu from "@/components/common/DropdownMenu";

const AddMediaPopup = dynamic(() => import("@/components/common/AddMediaPopup"));
const NewVoiceRecordDialog = dynamic(() => import("@/components/common/NewVoiceRecordDialog"));
const ChooseMediaTypeModal = dynamic(() => import("./ChooseMediaTypeModal"));

const UploadButton = ({ slug }) => {
  const { t } = useTranslation("common");
  const [isOpen, setOpen] = useState(false);
  const [mediaPopupOpen, setMediaPopupOpen] = useState(false);
  const [voiceRecordOpen, setVoiceRecordOpen] = useState(false);
  const [mediaTypeModalOpen, setMediaTypeModalOpen] = useState(false);

  return (
    <>
      <DropdownMenu
        isOpen={isOpen}
        setOpen={setOpen}
        title={<a className="button text-white">{t`upload.text`}</a>}
        className="hidden lg:inline-block"
      >
        <div className="w-24 p-2 lg:w-64">
          <button
            onClick={() => {
              setMediaTypeModalOpen(true);
              setOpen(false);
            }}
            type="button"
            className="button-text w-full text-left"
          >
            {t`upload.media`}
          </button>

          <button
            onClick={() => {
              setVoiceRecordOpen(true);
              setOpen(false);
            }}
            type="button"
            className="button-text w-full text-left"
          >
            {t`upload.voice-message`}
          </button>

          <Link href="/article/new">
            <a className="button-text block w-full text-left">{t`upload.blog`}</a>
          </Link>
        </div>
      </DropdownMenu>
      {mediaPopupOpen && <AddMediaPopup onClose={() => setMediaPopupOpen(false)} consultantSlug={slug} />}
      {voiceRecordOpen && <NewVoiceRecordDialog onClose={() => setVoiceRecordOpen(false)} />}
      {mediaTypeModalOpen && (
        <ChooseMediaTypeModal
          onClose={() => setMediaTypeModalOpen(false)}
          openMediaPopup={() => {
            setMediaTypeModalOpen(false);
            setMediaPopupOpen(true);
          }}
        />
      )}
    </>
  );
};

export default UploadButton;
